import { createStyles, Theme, makeStyles } from '@material-ui/core';
export const styles = (theme: Theme) =>
  createStyles({
    editorWrapperContainer: {
      overflow: 'auto',
    },
    headerActionsEditMode: {
      justifyContent: 'space-between',
    },
    scheduleButton: {
      marginRight: '10px',
    },
  });

export const useStyles = makeStyles((theme: Theme) => ({
  editorWrapperContainer: {
    overflow: 'auto',
  },
  headerActionsEditMode: {
    justifyContent: 'space-between',
  },
  scheduleButton: {
    marginRight: '10px',
  },
}));
