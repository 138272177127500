import React from 'react';
import { INavigationLink } from '@wings-shared/layout';
import LinkIcon from '@material-ui/icons/Link';
import { CloudUpload, ListAlt, Person } from '@material-ui/icons';
import {
  BroadcastIcon,
  AirplaneIcon,
  ClockIcon,
  SettingIcon,
  InfoIcon,
  AirportRunwayIcon,
  WarningIcon,
  CustomsIcon,
  SecurityIcon,
} from '@uvgo-shared/icons';
import { FAA_IMPORT_STAGING_ENTITY_TYPE } from '../../Enums';
import { Utilities } from '@wings-shared/core';

/* istanbul ignore next */
export const airportSidebarOptions = (defaultOptions: boolean, isDisabled?: boolean): INavigationLink[] =>
  defaultOptions ? defaultAirportOptions : upsertAirportOptions(isDisabled);

export const updatedAirportSidebarOptions = (tabQuery: string, searchQueryParams?: string): INavigationLink[] =>
  Utilities.updateSidebarOptions(defaultAirportOptions, tabQuery, searchQueryParams);

export const updateAirportSidebarOptions = (
  tabQuery: string,
  searchQueryParams?: string,
  isDisabled: boolean
): INavigationLink[] => Utilities.updateSidebarOptions(upsertAirportOptions(isDisabled), tabQuery, searchQueryParams);

export const defaultAirportOptions: INavigationLink[] = [
  { to: '', title: 'Airports', icon: <AirplaneIcon /> },
  { to: 'airport-hours', title: 'Airport Hours', icon: <ClockIcon /> },
  { to: 'bulletins', title: 'Bulletins', icon: <WarningIcon /> },
  { to: 'purged-bulletins', title: 'Purged Bulletins', icon: <WarningIcon /> },
  { to: 'import-faa', title: 'Import FAA', icon: <CloudUpload /> },
  { to: 'airport-mappings', title: 'Airport Mappings', icon: <LinkIcon /> },
  { to: 'airport-mappings-beta', title: 'Airport Mappings Beta', icon: <LinkIcon /> },
  { to: 'settings', title: 'Settings', icon: <SettingIcon /> },
];

export const upsertAirportOptions = (isDisabled: boolean): INavigationLink[] => [
  { to: '', title: 'General Information', icon: <InfoIcon /> },
  {
    to: 'operational-information',
    title: 'Operational Information',
    icon: <ListAlt />,
    isDisabled,
  },
  {
    to: 'flight-plan-information',
    title: 'Flight Plan Information',
    icon: <AirplaneIcon />,
    isDisabled,
  },
  { to: 'timezone-information', title: 'Timezone Information', icon: <ClockIcon />, isDisabled },
  { to: 'ownership', title: 'Ownership / Management', icon: <Person />, isDisabled },
  { to: 'airport-hours', title: 'Airport Hours', icon: <ClockIcon />, isDisabled },
  { to: 'runway', title: 'Runways', icon: <AirportRunwayIcon />, isDisabled },
  { to: 'runwayClosure', title: 'Runway Closure', icon: <AirportRunwayIcon />, isDisabled },
  { to: 'airport-frequencies', title: 'Airport Frequencies', icon: <BroadcastIcon />, isDisabled },
  { to: 'custom-detail/general', title: 'Custom Details', icon: <CustomsIcon />, isDisabled },
  {
    to: 'airport-security',
    title: 'Airport Security',
    icon: <SecurityIcon />,
    isDisabled,
  },
];

/* istanbul ignore next */
export const faaImportReviewSidebarOptions = (showRunways: boolean = false): INavigationLink[] => {
  return [
    { to: '', title: 'Review Information', icon: <AirplaneIcon />, replace: true },
    { to: 'runways', title: 'Runways', icon: <AirportRunwayIcon />, replace: true, isHidden: !showRunways },
  ];
};

/* istanbul ignore next */
// Options used in FAAFileDetails.tsx
export const faaImportFileDetailsSidebarOptions = (entity: FAA_IMPORT_STAGING_ENTITY_TYPE): INavigationLink[] => {
  switch (entity) {
    case FAA_IMPORT_STAGING_ENTITY_TYPE.FREQUENCY:
      return [{ to: '', title: 'Frequencies', icon: <BroadcastIcon />, replace: true }];
    case FAA_IMPORT_STAGING_ENTITY_TYPE.AIRPORT:
    case FAA_IMPORT_STAGING_ENTITY_TYPE.RUNWAYS:
      return [
        { to: 'airports', title: 'Airports', icon: <AirplaneIcon />, replace: true },
        { to: 'runways', title: 'Runways', icon: <AirportRunwayIcon />, replace: true },
      ];
    default:
      return defaultAirportOptions;
  }
};

/* istanbul ignore next */
export const customDetailSidebarOptions = (): INavigationLink[] => {
  return [
    { to: 'general', title: 'General Info', icon: <InfoIcon /> },
    { to: 'intl-customs-details', title: 'Non US Customs Details', icon: <InfoIcon /> },
  ];
};
