import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  exportBtn: {
    marginRight: 10,
  },
}));

export const styles = () =>
  createStyles({
    exportBtn: {
      marginRight: 10,
    },
  });