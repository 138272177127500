import { HttpClient, baseApiPath, BaseAirportStore } from '@wings/shared';
import { apiUrls } from './ApiUrls';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AlertStore } from '@uvgo-shared/alert';
import { AirportCustomGeneralModel, IntlCustomsDetailsModel } from '../Models';
import { IAPIAirportCustomGeneralRequest, IAPIIntlCustomsDetails } from '../Interfaces';
import { Logger } from '@wings-shared/security';

export class AirportCustomDetailStore extends BaseAirportStore {
  /* istanbul ignore next */
  public upsertGeneral(request: IAPIAirportCustomGeneralRequest): Observable<AirportCustomGeneralModel> {
    const http: HttpClient = new HttpClient({ baseURL: baseApiPath.airports });
    const isNewRequest: boolean = request.id === 0;
    const upsertRequest: Observable<IAPIAirportCustomGeneralRequest> = isNewRequest
      ? http.post<IAPIAirportCustomGeneralRequest>(`api/${request.airportId}/${apiUrls.customGeneralInfo}`, request)
      : http.put<IAPIAirportCustomGeneralRequest>(
        `api/${request.airportId}/${apiUrls.customGeneralInfo}/${request.id}`,
        request
      );

    return upsertRequest.pipe(
      Logger.observableCatchError,
      map(response => AirportCustomGeneralModel.deserialize(response)),
      tap(() => AlertStore.info(`General Information ${isNewRequest ? 'created' : 'updated'} successfully!`))
    );
  }

  public upsertIntlCustomsInfo(request: IntlCustomsDetailsModel): Observable<IntlCustomsDetailsModel> {
    const http: HttpClient = new HttpClient({ baseURL: baseApiPath.airports });
    const isNewRequest: boolean = request.id === 0;
    const upsertRequest: Observable<IAPIIntlCustomsDetails> = isNewRequest
      ? http.post<IAPIIntlCustomsDetails>(
        `api/airport/${request.airportId}/${apiUrls.intlCustomsInfo}`,
        request.serialize()
      )
      : http.put<IAPIIntlCustomsDetails>(
        `api/airport/${request.airportId}/${apiUrls.intlCustomsInfo}/${request.id}`,
        request.serialize()
      );

    return upsertRequest.pipe(
      Logger.observableCatchError,
      map(response => IntlCustomsDetailsModel.deserialize(response)),
      tap(() =>
        AlertStore.info(`International Customs Information ${isNewRequest ? 'created' : 'updated'} successfully!`)
      )
    );
  }
}
