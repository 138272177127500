import { CloudUploadOutlined, SwapHorizOutlined } from '@material-ui/icons';
import {
  AirplaneIcon, CalendarIcon,
  ClockIcon,
  LinkIcon,
  SettingIcon
} from '@uvgo-shared/icons';
import { Utilities } from '@wings-shared/core';
import { INavigationLink } from '@wings-shared/layout';
import React from 'react';

export const sidebarMenu: INavigationLink[] = [
  { to: 'time-conversion', title: 'Time Conversion', icon: <SwapHorizOutlined />, isHidden: true },
  {
    to: 'airport-time-zones',
    title: 'Airport Time Zones',
    icon: <AirplaneIcon />,
  },
  {
    to: 'airport-time-zone-review',
    title: 'Airport Time Zone Review',
    icon: <AirplaneIcon />,
  },
  {
    to: 'airport-time-zone-mapping',
    title: 'Airport Time Zone Mapping',
    icon: <LinkIcon />,
  },
  { to: 'time-zones', title: 'Time Zones', icon: <ClockIcon /> },
  {
    to: 'time-zone-review',
    title: 'Time Zone Review',
    icon: <ClockIcon />,
  },
  { to: 'events', title: 'World Events', icon: <CalendarIcon /> },
  { to: 'events-import', title: 'Import World Events ', icon: <CloudUploadOutlined /> },

  { to: 'settings', title: 'Settings', icon: <SettingIcon /> },
];

export const updateTimezoneSidebarOptions = (tabQuery: string): INavigationLink[] =>
  Utilities.updateSidebarOptions(sidebarMenu, tabQuery);
