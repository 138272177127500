import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  paperSize: {
    width: '60%',
    height: 500,
  },
}));

export const styles = (theme: Theme) =>
  createStyles({
    paperSize: {
      width: '60%',
      height: 500,
    },
  });
